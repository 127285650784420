import React from "react"
import { Link, graphql } from "gatsby"
import parse from "html-react-parser"

import Layout from "../components/layout"
import Seo from "../components/seo"

const ArticleIndex = ({
    data,
    pageContext: { nextPagePath, previousPagePath },
}) => {
    const posts = data.allWpPost.nodes

    if (!posts.length) {
        return (
            <Layout>
                <Seo title="All Article" />
                <p>
                    No Article found. Add posts to your WordPress site and they'll
                    appear here!
                </p>
            </Layout>
        )
    }

    return (
        <Layout>
            <Seo title="All Article" />

            <br></br>

            <ol style={{ listStyle: `none` }}>
                {posts.map(post => {
                    const title = post.title

                    return (
                        <li key={post.uri}>
                            <article
                                itemScope
                                itemType="http://schema.org/Article"
                            >
                                <header>
                                    <h2 className="SerifFont">
                                        <Link to={post.uri} itemProp="url">
                                            <span itemProp="headline">{parse(title)}</span>
                                        </Link>
                                    </h2>
                                    <small>{post.date}</small> <small>by {post.author?.node?.firstName} </small> <small>{post.author?.node?.firstName} </small>
                                </header>
                                <section itemProp="description">{parse(post.excerpt)}</section>
                                <div>
                                    Category:{" "}
                                    {post.categories.nodes.map((category, index) => [
                                        index > 0 && ", ",
                                        <Link key={index} to={category.link}>
                                            {category.name}
                                        </Link>,
                                    ])}
                                </div>
                                <br></br>
                                {/* <div>
                                    Tag:{" "}
                                    {post.tags.nodes.map((tag, index) => [
                                        index > 0 && ", ",
                                        <Link key={index} to={tag.link}>
                                            {tag.name}
                                        </Link>,
                                    ])}
                                </div> */}
                            </article>
                        </li>
                    )
                })}
            </ol>

            {previousPagePath && (
                <>
                    <Link to={previousPagePath}>Previous page</Link>
                    <br />
                </>
            )}
            {nextPagePath && <Link to={nextPagePath}>Next page</Link>}
        </Layout>
    )
}

export default ArticleIndex

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        author {
            node {
              lastName
              firstName
              id
            }
          }
          tags {
            nodes {
              name
              link
            }
          }
          categories {
            nodes {
              name
              link
            }
          } 
      }
    }
  }
`
